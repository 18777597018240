export default [
  {
    name: 'A',
    showColor: false,
    id: 'PP1BQS',
    date: 'November 12',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '15',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'B',
    id: 'GC1UGP',
    showColor: false,
    date: 'November 14',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '17',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'C',
    id: '6Z5RK0',
    showColor: false,
    date: 'November 14',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '18',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'D',
    id: 'V4A8NN',
    showColor: false,
    date: 'November 15',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '16',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'E',
    id: 'TDR6XQ',
    showColor: false,
    date: 'November 16',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '14',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'F',
    id: 'N7ZGZK',
    showColor: false,
    date: 'November 17',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '27',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'G',
    id: 'EDXJCY',
    showColor: false,
    date: 'November 18',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '10',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'H',
    id: '0L8XOZ',
    showColor: false,
    date: 'November 20',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '19',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'I',
    id: '6S4D5Y',
    showColor: false,
    date: 'November 21',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '20',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'J',
    id: '4JFD4P',
    showColor: false,
    date: 'November 23',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '21',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'K',
    id: 'Q25X3E',
    showColor: false,
    date: 'November 24',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '15',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'L',
    id: 'IGEFZM',
    showColor: false,
    date: 'November 24',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '20',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'M',
    id: 'K8PES1',
    showColor: false,
    date: 'November 25',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '20',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
  {
    name: 'N',
    id: 'BEYZDN',
    showColor: false,
    date: 'November 25',
    price: '---',
    css: 'linear-gradient(135deg, #93291E 0%, #bd3527 75%, #fff 100%)',
    weight: '20',
    status: 'Request Added',
    height: 50,
    ongoing: 'no',
    showIcon: false
  },
]
