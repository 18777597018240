import React, { useState } from 'react';
import * as firebase from 'firebase';
import logo from './logo.svg';
import Calendar from 'react-calendar';
import { differenceInCalendarDays } from 'date-fns';
import AwesomeSlider from 'react-awesome-slider';
import DatePicker from 'react-datepicker';
import firebaseinit from './firebase-credentials';
import moment from 'moment';
import {Helmet} from "react-helmet";
import Slider from 'rc-slider';
import Modal from 'tg-modal';
import ReactModal from 'react-modal';
import {useSpring, animated, useTransition} from 'react-spring';
import NumericInput from 'react-numeric-input';
import { addDays, addMonths, subDays, eachDay } from "date-fns";

import './App.css';
import 'react-calendar/dist/Calendar.css';
import 'rc-slider/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';

const disabledDates = [new Date(), new Date(2020, 9, 1, 0, 0, 0), new Date(2020, 9, 2, 0, 0, 0),new Date(2020, 9, 3, 0, 0, 0), new Date(2020, 9, 4, 0, 0, 0), new Date(2020, 9, 5, 0, 0, 0), new Date(2020, 9, 6, 0, 0, 0), new Date(2020, 9, 7, 0, 0, 0), new Date(2020, 9, 8, 0, 0, 0), new Date(2020, 9, 9, 0, 0, 0), new Date(2020, 9, 10, 0, 0, 0), new Date(2020, 9, 11, 0, 0, 0), new Date(2020, 9, 12, 0, 0, 0), new Date(2020, 9, 13, 0, 0, 0), new Date(2020, 9, 14, 0, 0, 0), new Date(2020, 9, 15, 0, 0, 0), new Date(2020, 9, 16, 0, 0, 0), new Date(2020, 9, 17, 0, 0, 0), new Date(2020, 9, 18, 0, 0, 0), new Date(2020, 9, 19, 0, 0, 0), new Date(2020, 9, 20, 0, 0, 0), new Date(2020, 9, 21, 0, 0, 0), new Date(2020, 9, 22, 0, 0, 0), new Date(2020, 9, 23, 0, 0, 0), new Date(2020, 9, 24, 0, 0, 0), new Date(2020, 9, 25, 0, 0, 0), new Date(2020, 9, 26, 0, 0, 0), new Date(2020, 9, 27, 0, 0, 0), new Date(2020, 9, 28, 0, 0, 0), new Date(2020, 9, 29, 0, 0, 0), new Date(2020, 9, 31, 0, 0, 0)];
const database = firebaseinit.database();

function isSameDay(a, b) {
  return differenceInCalendarDays(a, b) === 0;
}

function tileDisabled({ date, view }) {
  // Disable tiles in month view only
  if (view === 'month') {
    // Check if a date React-Calendar wants to check is on the list of disabled dates
    return disabledDates.find(dDate => isSameDay(dDate, date));
  }
}

function App() {
  const props = useSpring({opacity: 1, from: {opacity: 0}});
  const [on, toggle] = useState(false);
   const [value, setValue] = useState(new Date());
   const [showbookingdate, setshowbookingdate] = useState(false);
   const [showdeliverydate, setshowdeliverydate] = useState(false);
   const [weight, setweight] = useState('0');
   const [bookingdate, setbookingdate] = useState(new Date());
   const [deliverydate, setdeliverydate] = useState(new Date());
   const [bookingbutton, setbookingbutton] = useState('Booking Date');
   const [deliverybutton, setdeliverybutton] = useState('Delivery Date');
   const [shipping, setshipping] = useState('0');

   const [startDate, setStartDate] = useState('');

   const [isOpen, setOpen] = useState(false);

   const [bname, setbname] = useState('');
   const [person, setperson] = useState('');
   const [bemail, setemail] = useState('');
   const [bnum, setnum] = useState('');
   const [ack, setack] = useState(false);

   const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom-1": [
        new Date(2020, 10, 1),
        new Date(2020, 10, 2),
        new Date(2020, 10, 3),
        new Date(2020, 10, 4),
        new Date(2020, 10, 5),
        new Date(2020, 10, 6),
        new Date(2020, 10, 7),
        new Date(2020, 10, 8),
        new Date(2020, 10, 9),
        new Date(2020, 10, 10),
        new Date(2020, 10, 11),
        new Date(2020, 10, 12),
        new Date(2020, 10, 13),
        new Date(2020, 10, 14),
        new Date(2020, 10, 15),
        new Date(2020, 10, 16),
        new Date(2020, 10, 17),
        new Date(2020, 10, 18),
        new Date(2020, 10, 19),
        new Date(2020, 10, 20),
        new Date(2020, 10, 21),
        new Date(2020, 10, 22),
        new Date(2020, 10, 23),
        new Date(2020, 10, 24),
        new Date(2020, 10, 25),
        new Date(2020, 10, 26),
      ]
    },
    {
      "react-datepicker__day--highlighted-custom-2": [
       new Date(2020, 10, 27),
       new Date(2020, 10, 28),
       new Date(2020, 10, 29),
       new Date(2020, 10, 30),
       new Date(2020, 11, 1),
       new Date(2020, 11, 2),
       new Date(2020, 11, 3),
       new Date(2020, 11, 4),
       new Date(2020, 11, 5),
       new Date(2020, 11, 6),
       new Date(2020, 11, 7),
       new Date(2020, 11, 8),
       new Date(2020, 11, 9),
       new Date(2020, 11, 10),
       new Date(2020, 11, 11),
       new Date(2020, 11, 12),
       new Date(2020, 11, 13),
       new Date(2020, 11, 14),
       new Date(2020, 11, 15),
      ]
    },
    {
      "react-datepicker__day--highlighted-custom-3": [
       new Date(2020, 11, 16),
       new Date(2020, 11, 17),
       new Date(2020, 11, 18),
       new Date(2020, 11, 19),
       new Date(2020, 11, 20),
       new Date(2020, 11, 21),
       new Date(2020, 11, 22),
       new Date(2020, 11, 23),
       new Date(2020, 11, 24),
       new Date(2020, 11, 25),
       new Date(2020, 11, 26),
       new Date(2020, 11, 27),
       new Date(2020, 11, 28),
       new Date(2020, 11, 29),
       new Date(2020, 11, 30),
       new Date(2020, 11, 31),
      ]
    },
    {
      "react-datepicker__day--highlighted-custom-4": [
       new Date(2021, 0, 1),
       new Date(2021, 0, 2),
       new Date(2021, 0, 3),
       new Date(2021, 0, 4),
       new Date(2021, 0, 5),
       new Date(2021, 0, 6),
       new Date(2021, 0, 7),
       new Date(2021, 0, 8),
       new Date(2021, 0, 9),
       new Date(2021, 0, 10),
       new Date(2021, 0, 11),
       new Date(2021, 0, 12),
       new Date(2021, 0, 13),
       new Date(2021, 0, 14),
       new Date(2021, 0, 15),
       new Date(2021, 0, 16),
       new Date(2021, 0, 17),
       new Date(2021, 0, 18),
       new Date(2021, 0, 19),
       new Date(2021, 0, 20),
       new Date(2021, 0, 21),
       new Date(2021, 0, 22),
       new Date(2021, 0, 23),
       new Date(2021, 0, 24),
       new Date(2021, 0, 25),
       new Date(2021, 0, 26),
       new Date(2021, 0, 27),
       new Date(2021, 0, 28),
       new Date(2021, 0, 29),
       new Date(2021, 0, 30),
       new Date(2021, 0, 31),
      ]
    },
  ];

   const [route, setRoute] = useState('NA');

   const [showfees, setshowfees] = useState(false);

   const [fee, setfee] = useState('----');

   function onChangebdate(nextValue) {
    console.log(nextValue);
    setbookingdate(nextValue);    
    let date = nextValue.getDate() + '/' + nextValue.getMonth() + '/' +nextValue.getFullYear();
    setbookingbutton(date);
    showbdate();
    calculate(nextValue);
  }

  function calculate(nextValue) {
    let bdate = nextValue.getDate();
    let shippingchgs = '400/kg';
    console.log(weight);
    let difference = 23 - nextValue.getDate();
    if( difference > 10 )
    {
      console.log('no surge');

    }
    if(( difference > 4 ) && ( difference <= 10 ))
  {
    console.log('surge');
    shippingchgs = '445/kg';
  }
  if(( difference <= 4 ))
  {
    console.log('Max Surge');
    shippingchgs = '460/kg';
  }
  setshipping(shippingchgs);

  }

  function onChangeddate(nextValue) {
    setdeliverydate(nextValue);
    let date = nextValue.getDate() + '/' + nextValue.getMonth() + '/' +nextValue.getFullYear();
    setdeliverybutton(date);
    showddate();
  }

  function showbdate() {
    setshowbookingdate(!showbookingdate);
  }

  function showddate() {
    setshowdeliverydate(!showdeliverydate);
  }

  function changeRouteBoth() {
    setRoute('BOTH');
    calculateRateRoute('BOTH');
  }

  function changeRouteDomestic() {
    setRoute('DOMESTIC');
    calculateRateRoute('DOMESTIC');
  }

  function changeRouteInternational() {
    setRoute('INTERNATIONAL');
    calculateRateRoute('INTERNATIONAL');
  } 

  function changeDate(date) {
    setStartDate(date);
    calculateRateDate(date);
  }

  function changeWeight(weight) {
    setweight(weight);
    calculateRateWeight(weight);
  }

  function calculateRateDate(date) {
    const month = moment(date).format('MMM');
    const day = moment(date).format('D');
    let rate = 0;
    let routeval = route;
    const weightval = weight;
    if (weightval === 0) {
      routeval = 'NA';
    }
    if (routeval === 'BOTH') {
      if (month === 'Nov') {
        console.log(routeval);
        console.log(month);
        console.log(weightval);
        if ((day >= 1) && (day <= 26)) {
          rate = 360;
        }
        if (day > 26) {
          rate = 440;
        }
      }
      if (month === 'Dec') {
        if ((day >= 1) && (day <= 15)) {
          rate = 440;
        }
        if ((day > 15) && (day <= 31)) {
          rate = 460;
        }
      }
      if (month === 'Jan') {
        rate = 376;
      }
      if (month === 'Feb') {
        rate = 376;
      }
    }
    if (routeval === 'DOMESTIC') {
      if (month === 'Nov') {
        console.log(routeval);
        console.log(month);
        console.log(weightval);
        if ((day >= 1) && (day <= 26)) {
          rate = 810;
        }
        if (day > 26) {
          rate = 990;
        }
      }
      if (month === 'Dec') {
        if ((day >= 1) && (day <= 15)) {
          rate = 990;
        }
        if ((day > 15) && (day <= 31)) {
          rate = 1035;
        }
      }
      if (month === 'Jan') {
        rate = 846;
      }
      if (month === 'Feb') {
        rate = 846;
      }
    }
    if (routeval === 'INTERNATIONAL') {
      if (month === 'Nov') {
        console.log(routeval);
        console.log(month);
        console.log(weightval);
        if ((day >= 1) && (day <= 26)) {
          rate = 720;
        }
        if (day > 26) {
          rate = 880;
        }
      }
      if (month === 'Dec') {
        if ((day >= 1) && (day <= 15)) {
          rate = 880;
        }
        if ((day > 15) && (day <= 31)) {
          rate = 920;
        }
      }
      if (month === 'Jan') {
        rate = 752;
      }
      if (month === 'Feb') {
        rate = 752;
      }
    }
    if (rate > 0) {
      const feeforallval =  rate * weightval;
      setshowfees(true);
      setfee(feeforallval);
    }
    if (rate === 0) {
      setshowfees(false);
      setfee('----');
    }
    console.log(rate);
  }

  function calculateRateWeight(weight) {
    const date = startDate;
    const month = moment(date).format('MMM');
    const day = moment(date).format('D');
    let rate = 0;
    let routeval = route;
    const weightval = weight;
    if (weightval === 0) {
      routeval = 'NA';
    }
    if (routeval === 'BOTH') {
      if (month === 'Nov') {
        console.log(routeval);
        console.log(month);
        console.log(weightval);
        if ((day >= 1) && (day <= 26)) {
          rate = 360;
        }
        if (day > 26) {
          rate = 440;
        }
      }
      if (month === 'Dec') {
        if ((day >= 1) && (day <= 15)) {
          rate = 440;
        }
        if ((day > 15) && (day <= 31)) {
          rate = 460;
        }
      }
      if (month === 'Jan') {
        rate = 376;
      }
      if (month === 'Feb') {
        rate = 376;
      }
    }
    if (routeval === 'DOMESTIC') {
      if (month === 'Nov') {
        console.log(routeval);
        console.log(month);
        console.log(weightval);
        if ((day >= 1) && (day <= 26)) {
          rate = 810;
        }
        if (day > 26) {
          rate = 990;
        }
      }
      if (month === 'Dec') {
        if ((day >= 1) && (day <= 15)) {
          rate = 990;
        }
        if ((day > 15) && (day <= 31)) {
          rate = 1035;
        }
      }
      if (month === 'Jan') {
        rate = 846;
      }
      if (month === 'Feb') {
        rate = 846;
      }
    }
    if (routeval === 'INTERNATIONAL') {
      if (month === 'Nov') {
        console.log(routeval);
        console.log(month);
        console.log(weightval);
        if ((day >= 1) && (day <= 26)) {
          rate = 720;
        }
        if (day > 26) {
          rate = 880;
        }
      }
      if (month === 'Dec') {
        if ((day >= 1) && (day <= 15)) {
          rate = 880;
        }
        if ((day > 15) && (day <= 31)) {
          rate = 920;
        }
      }
      if (month === 'Jan') {
        rate = 752;
      }
      if (month === 'Feb') {
        rate = 752;
      }
    }
    if (rate > 0) {
      const feeforallval = rate * weightval;
      setshowfees(true);
      setfee(feeforallval);
    }
    if (rate === 0) {
      setshowfees(false);
      setfee('----');
    }
  }

  function calculateRateRoute(route) {
    const date = startDate;
    const month = moment(date).format('MMM');
    console.log(month);
    const day = moment(date).format('D');
    let rate = 0;
    let routeval = route;
    const weightval = weight;
    if (weightval === 0) {
      console.log('here');
      routeval = 'NA';
    }
    if (routeval === 'BOTH') {
      console.log('here1');
      if (month === 'Nov') {
        console.log(routeval);
        console.log(month);
        console.log(weightval);
        if ((day >= 1) && (day <= 26)) {
          rate = 360;
        }
        if (day > 26) {
          rate = 440;
        }
      }
      if (month === 'Dec') {
        if ((day >= 1) && (day <= 15)) {
          rate = 440;
        }
        if ((day > 15) && (day <= 31)) {
          rate = 460;
        }
      }
      if (month === 'Jan') {
        rate = 376;
      }
      if (month === 'Feb') {
        rate = 376;
      }
    }
    if (routeval === 'DOMESTIC') {
      console.log('here2');
      if (month === 'Nov') {
        console.log(routeval);
        console.log(month);
        console.log(weightval);
        if ((day >= 1) && (day <= 26)) {
          rate = 810;
        }
        if (day > 26) {
          rate = 990;
        }
      }
      if (month === 'Dec') {
        if ((day >= 1) && (day <= 15)) {
          rate = 990;
        }
        if ((day > 15) && (day <= 31)) {
          rate = 1035;
        }
      }
      if (month === 'Jan') {
        rate = 846;
      }
      if (month === 'Feb') {
        rate = 846;
      }
    }
    if (routeval === 'INTERNATIONAL') {
      console.log('here3');
      if (month === 'Nov') {
        console.log(routeval);
        console.log(month);
        console.log(weightval);
        if ((day >= 1) && (day <= 26)) {
          rate = 720;
        }
        if (day > 26) {
          rate = 880;
        }
      }
      if (month === 'Dec') {
        if ((day >= 1) && (day <= 15)) {
          rate = 880;
        }
        if ((day > 15) && (day <= 31)) {
          rate = 920;
        }
      }
      if (month === 'Jan') {
        rate = 752;
      }
      if (month === 'Feb') {
        rate = 752;
      }
    }
    console.log(rate);
    if (rate > 0) {
      const feeforallval = rate * weightval;
      setshowfees(true);
      setfee(feeforallval);
    }
    if (rate === 0) {
      setshowfees(false);
      setfee('----');
    }
    
  }

const transition = useTransition(on, null, {
from: { opacity: 0 },
enter: { opacity: 1 },
leave: { opacity: 0 }
});

function toggleModal() {
  setOpen(!isOpen);
}

function submitCP() {
  const name = bname;
  const pername = person;
  const email = bemail;
  const number = bnum;
  console.log(name);

  const cp = {
    businessname : name,
    businessperson : pername,
    businessnum : number,
    businessemail : email,
  };

  database.ref('beckbagpartner').child(number).update(cp);

  setack(true);


}  

  return (
    <div className="App" style={{ background: 'white' }}>
    <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>BeckBags | Monetize excess space</title>
        <meta
          name="description"
          content="Economising travel fees and simplifying shipping"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="BeckBags for Travel | Monetize excess space" />
        <meta
          itemprop="description"
          content="Economising travel fees and simplifying shipping"
        />
        <meta
          itemprop="image"
          content="https://firebasestorage.googleapis.com/v0/b/project-1348531828359008657.appspot.com/o/Travel%20for%20Business.png?alt=media&token=d1459dd1-db75-4d98-99a4-27a2cfff3f89"
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://beckbags.in" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="BeckBags for Travel | Monetize excess space" />
        <meta
          property="og:description"
          content="Economising travel fees and simplifying shipping"
        />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/project-1348531828359008657.appspot.com/o/Travel%20for%20Business.png?alt=media&token=d1459dd1-db75-4d98-99a4-27a2cfff3f89"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="BeckBags for Travel | Monetize excess space" />
        <meta
          name="twitter:description"
          content="Economising travel fees and simplifying shipping"
        />
        <meta
          name="twitter:image"
          content="https://firebasestorage.googleapis.com/v0/b/project-1348531828359008657.appspot.com/o/Travel%20for%20Business.png?alt=media&token=d1459dd1-db75-4d98-99a4-27a2cfff3f89"
        />
      </Helmet>
      <section className="container-pic-beckbags newbeckbagback">
        <img className="beckbaglogo" src={'https://firebasestorage.googleapis.com/v0/b/beckfriends-2-a4131.appspot.com/o/transmodifiedsmallalt.png?alt=media&token=2d855b9f-2e04-41d9-a946-6c18e9b4f4c6'} alt="profilePic" style={{ width: '15%', marginTop: '10px', marginLeft: '5px' }} />
        <div className="row" style={{ padding: '10px', marginTop: '8%', marginRight: '0px', marginLeft: '0px' }}>
          <div className="col-md-6">
            <h1 className="mobiletext" style={{ fontSize: '40px', textAlign: 'left', marginLeft: '15px', color: 'white' }}>Allow your users to avail <span className="bolding mobilebolding">discounts</span> while you <span className="bolding mobilebolding">earn more</span> per customer</h1>
            <button onClick={toggleModal} className="btn btn-primary inlineblock buybtn_1 buybtn_2" style={{ backgroundColor: 'white', color: 'red', border: 'none', outline: 'none', letterSpacing: 2, fontWeight: 600, borderRadius: '5px', textTransform: 'uppercase', marginLeft: '15px', padding: '1em', float: 'left', fontSize: '1.1em', marginTop: '20px' }}>Request Access</button>
          </div>
          <div className="col-md-5 mobilehide" style={{ float: 'right', background: 'white', padding: '10px', borderRadius: '15px', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)' }}>
            <h1 className="headertext2" style={{ color: '#007272', fontWeight: 800, fontSize: '1.5em', marginTop: '10px',marginBottom: '1em' }}>Estimate how much your users can earn</h1>
            <div className="row" style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', background: 'white', margin: 0, marginTop: '3.2em' }}>
              <div className="col-md-6">
                <i className="fa fa-calendar col-xs-2" aria-hidden="true" style={{ fontSize: '1.4em', color: '#007272' }} />
                <DatePicker dateFormat="MMMMMMMM yyyy" selected={startDate==="" ? null : startDate} onChange={date => changeDate(date)} placeholderText="Select travel date" highlightDates={highlightWithRanges} minDate={new Date(2020, 10, 1 )} maxDate={addMonths(new Date(), 4)} />
              </div>
              <div className="col-md-6" style={{ marginTop: '-10px' }}>
                <div className="btn btn-default col-md-3 col-xs-4 col-sm-2" style={{ padding: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', border: 'none', outline: 'none', backgroundColor: 'transaparent', fontSize: '1em', fontFamily: 'sans-serif', color: '#000', cursor: 'none', lineHeight: '1.2' }}><input value={weight} style={{ width: '100%', display: 'block', textAlign: 'center', fontSize: '16px', border: 'none', borderBottom: '1px solid #a8a8a8', marginBottom: '0.25em', background: 'transparent' }} readOnly />Weight</div>
                <div className="col-md-9 col-xs-8 col-sm-4"><Slider style={{ padding: '16px 0' }} max={30} min={0} step={5} marks={5} handleStyle={{ background: 'rgb(0, 114, 114)', border: 'none' }} onChange={weight => changeWeight(weight)} value={weight} /></div>
              </div>
          </div>
              <div className="row" style={{ width: '100%', margin: '1em auto', maxWidth: 500 }}>
                <button style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0, border: '2px solid white', borderRightWidth: '0px' }} className={(route === 'BOTH') ? 'btn btn-default col-md-4 col-xs-4 tabtns weightbtns activez' : 'btn btn-default col-md-4 col-xs-3 weightbtns'} onClick={changeRouteBoth}>🇦🇪<span style={{ fontSize: '1.2em', marginLeft: '10px' }}>India to Dubai</span></button>
                <button style={{ borderRadius: 0, border: '2px solid white', borderRightWidth: '0px' }} className={(route === 'DOMESTIC') ? 'btn btn-default col-md-4 col-xs-4 weightbtns activez' : 'btn btn-default col-md-4 col-xs-4 weightbtns'} onClick={changeRouteDomestic}>🇺🇸<span style={{ fontSize: '1.2em', marginLeft: '10px' }}>India to USA</span></button>
                <button style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0, border: '2px solid white' }} className={(route === 'INTERNATIONAL') ? 'btn btn-default col-md-4 col-xs-4 weightbtns activez' : 'btn btn-default col-md-4 col-xs-5 weightbtns'} onClick={changeRouteInternational}>🇬🇧<span style={{ fontSize: '1.2em', marginLeft: '10px' }}>UK to India</span></button>
              </div>
          <h2 className="headertext" style={{ width: '100%', marginTop: '1em', paddingBottom: '0.5em', fontSize: '2em', textAlign: 'center', color: 'red' }}>Earn fees upto ₹ {fee}</h2>  
          </div>
        </div>
      </section>
      <div className="row mobilerow" style={{ marginTop: '5%', marginRight: '0px', marginLeft: '0px', display: 'none' }}>
          <div className="col-md-12" style={{ width: '100%', float: 'right', background: 'white', padding: '10px', borderRadius: '15px' }}>
            <h1 className="headertext" style={{ color: '#007272', fontWeight: 700, fontSize: '1.9em', marginBottom: '10px', marginTop: '0px' }}>Join our network</h1>
            <h1 className="headertext2" style={{ fontWeight: 300, fontSize: '1.3em', marginTop: '0px',marginBottom: '1em' }}>Estimate how much your users can earn</h1>
            <div className="row" style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', background: 'white', margin: 0, marginTop: '3em' }}>
              <div className="col-md-12">
                <i className="fa fa-calendar col-xs-2" aria-hidden="true" style={{ fontSize: '1.4em', color: '#007272' }} />
                <DatePicker className="fa fa-calendar col-xs-8" dateFormat="MMMMMMMM yyyy" selected={startDate==="" ? null : startDate} onChange={date => changeDate(date)} placeholderText="Select travel date" highlightDates={highlightWithRanges} minDate={new Date(2020, 10, 1 )} maxDate={addMonths(new Date(), 4)} />
              </div>
          </div>
          <div className="row" style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', background: 'white', margin: 0, marginTop: '2em' }}>
          <div className="col-md-12" style={{ marginTop: '-10px', width: '100%' }}>
                <div className="btn btn-default col-md-3 col-xs-4 col-sm-2" style={{ padding: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', border: 'none', outline: 'none', backgroundColor: 'transaparent', fontSize: '1em', fontFamily: 'sans-serif', color: '#000', cursor: 'none', lineHeight: '1.2' }}><input value={weight} style={{ width: '100%', display: 'block', textAlign: 'center', fontSize: '16px', border: 'none', borderBottom: '1px solid #a8a8a8', marginBottom: '0.25em', background: 'transparent' }} readOnly />Weight</div>
                <div className="col-md-9 col-xs-8 col-sm-4"><Slider style={{ padding: '16px 0' }} max={30} min={0} step={5} marks={5} handleStyle={{ background: 'rgb(0, 114, 114)', border: 'none' }} onChange={weight => changeWeight(weight)} value={weight} /></div>
              </div>
              </div>
              <div className="row" style={{ width: '100%', margin: '1em auto', maxWidth: 500 }}>
                <button style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0, border: '2px solid white', borderRightWidth: '0px' }} className={(route === 'BOTH') ? 'btn btn-default col-md-4 col-xs-4 tabtns weightbtns activez' : 'btn btn-default col-md-4 col-xs-3 weightbtns'} onClick={changeRouteBoth}>🇦🇪<span style={{ fontSize: '1em', marginLeft: '10px' }}>Dubai</span></button>
                <button style={{ borderRadius: 0, border: '2px solid white', borderRightWidth: '0px' }} className={(route === 'DOMESTIC') ? 'btn btn-default col-md-4 col-xs-4 weightbtns activez' : 'btn btn-default col-md-4 col-xs-4 weightbtns'} onClick={changeRouteDomestic}>🇺🇸<span style={{ fontSize: '1em', marginLeft: '10px' }}>USA</span></button>
                <button style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0, border: '2px solid white' }} className={(route === 'INTERNATIONAL') ? 'btn btn-default col-md-4 col-xs-4 weightbtns activez' : 'btn btn-default col-md-4 col-xs-5 weightbtns'} onClick={changeRouteInternational}>🇬🇧<span style={{ fontSize: '1em', marginLeft: '10px' }}>UK</span></button>
              </div>
          <h2 className="headertext" style={{ width: '100%', marginTop: '1em', paddingBottom: '0.5em', fontSize: '2em', textAlign: 'center', color: 'red' }}>Earn fees upto ₹ {fee}</h2>  
          </div>
        </div>
        <ReactModal
           className="ModalReq"
           isOpen={isOpen}
           contentLabel="Modal #1 Global Style Override Example"
           onRequestClose={toggleModal}
        >
          { !ack && <h4 className="headertext2 header3" style={{ width: '100%', fontWeight: 800, color: '#000', marginBottom: '2em', fontSize: '1.4em', textAlign: 'center' }}>Please share your details here</h4>}
          <button onClick={toggleModal} style={{ position: 'absolute', top: '10px', right: '10px', border: '0px', background: 'white' }}>❌</button>
          { !ack && <input className="another buydiv mobilepartner" type="text" placeholder="Business Name" value={bname} onChange={setbname} onChange={event => setbname(event.target.value)} style={{ width: '100%', border: '1px solid Black', borderWidth: '0px', background: '#ffffff', fontWeight: 600, marginBottom: '1em', borderBottomWidth: '1px' }} />}
              { !ack && <input className="another buydiv mobilepartner" type="text" placeholder="Contact Person" value={person} onChange={event => setperson(event.target.value)} style={{ width: '100%', border: '1px solid Black', borderWidth: '0px', background: '#ffffff', fontWeight: 600, marginBottom: '1em', borderBottomWidth: '1px' }} />}
              { !ack && <input className="another buydiv mobilepartner" type="text" placeholder="Contact Email" value={bemail} onChange={event => setemail(event.target.value)} style={{ width: '100%', border: '1px solid Black', borderWidth: '0px', background: '#ffffff', fontWeight: 600, marginBottom: '1em', borderBottomWidth: '1px' }} />}
              { !ack && <input className="another buydiv mobilepartner" type="text" placeholder="Contact Number" value={bnum} onChange={event => setnum(event.target.value)} style={{ width: '100%', border: '1px solid Black', borderWidth: '0px', background: '#ffffff', fontWeight: 600, marginBottom: '1em', borderBottomWidth: '1px' }} />}
              { !ack && <button onClick={submitCP} className="btn btn-primary inlineblock buybtn_1 buybtn_2  mobilepartner" style={{ backgroundColor: 'rgb(33, 108, 85)', border: 'none', outline: 'none', letterSpacing: 1, fontWeight: 600, width: '85%', borderRadius: '5px', textTransform: 'uppercase', width: '100%' }}>Submit</button>}
              { ack && <h4 className="headertext2 header3" style={{ width: '100%', fontWeight: 800, color: 'green', marginBottom: '1em', fontSize: '1.4em', textAlign: 'center' }}>Submitted Successfully ✅</h4>}
              { ack && <h4 className="headertext2 header3" style={{ width: '100%', fontWeight: 800, color: 'green', fontSize: '1.4em', textAlign: 'center' }}>Our team will be in touch shortly</h4>}
        </ReactModal>
      <div style={{ backgroundColor: '#f7f9fa', margin: '0 auto', padding: '1em 1em 1em', marginBottom: '15px' }}>
          <div className="row">
          <h2 style={{ marginBottom: '1em', marginTop: '1em', fontWeight: 600, color: 'rgb(0, 114, 114)' }}>Why use BeckBags ?</h2>
            <div className="col-md-4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <i className="fa fa-database col-xs-2" aria-hidden="true" style={{ fontSize: '2.4em', padding: '5px 0 0', color: 'red' }} />
              <p style={{ marginTop: '1em', fontSize: '1.5em', textAlign: 'center' }}>All route wise shipment data in one place</p>
            </div>
            <div className="col-md-4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <i className="fa fa-money col-xs-2" aria-hidden="true" style={{ fontSize: '2.4em', padding: '5px 0 0', color: 'red' }} />
              <p style={{ marginTop: '1em', fontSize: '1.5em', textAlign: 'center' }}>Dynamic recommended pricing to increase revenues</p>
            </div>
            <div className="col-md-4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <i className="fa fa-compress col-xs-2" aria-hidden="true" style={{ fontSize: '2.4em', padding: '5px 0 0', color: 'red' }} />
              <p style={{ marginTop: '1em', fontSize: '1.5em', textAlign: 'center' }}>API integration into your booking engine</p>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#ffffff', margin: '0 auto', padding: '1em 1em 1em', marginBottom: '15px' }}>
        <div style={{ backgroundColor: '#ffffff', width: '100%' }}>
          <div className="row" style={{ margin: '0 auto' }}>
            <h1 className="headertextdivs" style={{ width: '100%', fontWeight: 600, marginBottom: '0.5em', color: '#007272' }}>It’s easy to get started</h1>
            <div className="row" style={{ paddingTop: '1em', margin: 0 }}>
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <div className="col-md-3 col-xs-12 margin-left-resp" style={{ paddingLeft: 0 }}>
                    <h1 className="headertextdivs" style={{ width: '100%', fontWeight: 500, fontSize: '2em', marginBottom: '1em' }}>Sign Up Online</h1>
                    <h1 className="headertextsubs" style={{ color: '#57585a', width: '100%', fontSize: '1.1em', fontWeight: 300 }}>Share your business and contact details with us and our representative will get in touch</h1>
                  </div>
                  <div className="col-md-3 col-xs-12" style={{ padding: '0 0.5em' }}>
                    <h1 className="headertextdivs mobilemargintop" style={{ width: '100%', fontWeight: 500, fontSize: '2em', marginBottom: '1em' }}>Share your documents</h1>
                    <h1 className="headertextsubs" style={{ color: '#57585a', width: '100%', fontSize: '1.1em', fontWeight: 300 }}>Share your Registration, business proof and the other necessary information once in order to be approved</h1>
                  </div>
                  <div className="col-md-3 col-xs-12 margin-top-resp" style={{ paddingLeft: 0 }}>
                    <h1 className="headertextdivs mobilemargintop" style={{ width: '100%', fontWeight: 500, fontSize: '2em', marginBottom: '1em' }}>Integrate into booking</h1>
                    <h1 className="headertextsubs" style={{ color: '#57585a', width: '100%', fontSize: '1.1em', fontWeight: 300 }}>The api details will be provided to integrate into your booking system so your travelers can start sharing luggage space</h1>
                  </div>
                  <div className="col-md-3 col-xs-12 margin-top-resp" style={{ paddingLeft: 0 }}>
                    <h1 className="headertextdivs mobilemargintop" style={{ width: '100%', fontWeight: 500, fontSize: '2em', marginBottom: '1em' }}>Get go with earning</h1>
                    <h1 className="headertextsubs" style={{ color: '#57585a', width: '100%', fontSize: '1.1em', fontWeight: 300 }}>We’ll provide the dashboard to manage the space monetized and to track payments, schedule etc</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>

  );
}

export default App;
