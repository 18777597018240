import { render } from 'react-dom';
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { flash } from 'react-animations';
import { motion } from "framer-motion";
import {Helmet} from "react-helmet";
import shuffle from 'lodash/shuffle';
import AirlineSeatReclineExtra from '@material-ui/icons/AirlineSeatReclineExtra';
import GroupIcon from '@material-ui/icons/Group';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import data from './data';
import travelerdata from './travelerdata';
import './styles.css';
import './index.css';

const bounceAnimation = keyframes`${flash}`;
 
const BouncyDiv = styled.div`
  animation: 2s ${bounceAnimation};
`;

function array_move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
};

function AppAltOne() {
  const [rows, set] = useState(data)
  const [rowstrav, settrav] = useState(travelerdata)
  const [weight, setweight] = useState(0)
  const [revenue, setFee] = useState(0)
  const [profit, setProfit] = useState(0)
  
  /////actual stuff now

  useEffect(() => void setTimeout(() => {
    rows[0].showIcon = true;
    rows[0].status = 'C';
    rows[0].price = '400 ⇿';
    //let shuffled = array_move(rows,0,0);
    set([...rows]);
    rowstrav[2].matched = 'PP1BQS';
    rowstrav[2].showIcon = true;
    rowstrav[2].description = '✅';
    rowstrav[2].monetized = '15';
    setweight(15);
    let fee = 300*15;
    let revenue = 400*15;
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit);
    settrav([...rowstrav]);
    } , 1500), []);

  useEffect(() => void setTimeout(() => {
    rows[0].showColor = true;
    rowstrav[2].showColor = true;

    rows[1].showIcon = true;
    rows[1].status = 'A';
    rows[1].price = '400 ⇿';
    rowstrav[0].matched = 'GC1UGP';
    rowstrav[0].showIcon = true;
    rowstrav[0].description = '8';
    rowstrav[0].monetized = '17';
    setweight(32);
    let fee = 300*(15+17);
    let revenue = 400*(15+17);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit);
    settrav([...rowstrav]);
    set([...rows]);
    } , 3550), []);

  useEffect(() => void setTimeout(() => {
    rows[1].showColor = true;

    rows[2].showIcon = true;
    rows[2].status = 'A+B';
    rows[2].price = '400 ⇿';
    rowstrav[0].matchedtwoview = true;
    rowstrav[0].matchedtwo = '6Z5RK0';
    rowstrav[0].showIcon = false;
    rowstrav[0].showIcontwo = true;
    rowstrav[0].description = '✅';
    rowstrav[0].monetized = '25';

    rowstrav[1].matched = '6Z5RK0';
    rowstrav[1].showIcon = true;
    rowstrav[1].description = '10';
    rowstrav[1].monetized = '10';

    setweight(50);
    let fee = 300*(15+17+18);
    let revenue = 400*(15+17+18);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit);
    settrav([...rowstrav]);
    set([...rows]);
    } , 5600), []);

  useEffect(() => void setTimeout(() => {
    //////start from jere

    rows[2].showColor = true;
    rowstrav[0].showColor = true;

    rows[3].showIcon = true;
    rows[3].status = 'B+D';
    rows[3].price = '420 ⇧';
    set([...rows]);

    rowstrav[1].matchedtwoview = true;
    rowstrav[1].matchedtwo = 'V4A8NN';
    rowstrav[1].showIcon = false;
    rowstrav[1].showIcontwo = true;
    rowstrav[1].description = '✅';
    rowstrav[1].monetized = '20';

    rowstrav[3].matched = 'V4A8NN';
    rowstrav[3].showIcon = true;
    rowstrav[3].description = '14';
    rowstrav[3].monetized = '6';

    setweight(66);
    let fee = 300*(15+17+18+16);
    let revenue = 400*(15+17+18) + 420*16;
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit);

    settrav([...rowstrav]);
    
    } , 7650), []);

  useEffect(() => void setTimeout(() => {

    rows[3].showColor = true;
    rowstrav[1].showColor = true;

    rows[4].showIcon = true;
    rows[4].status = 'D';
    rows[4].price = '420 ⇧';
    set([...rows]);

    rowstrav[3].matchedtwoview = true;
    rowstrav[3].matchedtwo = 'TDR6XQ';
    rowstrav[3].showIcon = false;
    rowstrav[3].showIcontwo = true;
    rowstrav[3].description = '✅';
    rowstrav[3].monetized = '20';

     setweight(80);
    let fee = 300*(15+17+18+16+14);
    let revenue = 400*(15+17+18) + 420*(16+14);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit);


    settrav([...rowstrav]);

  } , 9700), []);

  useEffect(() => void setTimeout(() => {

    rows[4].showColor = true;
    rowstrav[3].showColor = true;

    rows[5].showIcon = true;
    rows[5].status = 'E+F';
    rows[5].price = '420 ⇧';
    set([...rows]);

    rowstrav[4].matched = 'N7ZGZK';
    rowstrav[4].showIcon = true;
    rowstrav[4].description = '✅';
    rowstrav[4].monetized = '15';

    rowstrav[5].matched = 'N7ZGZK';
    rowstrav[5].showIcon = true;
    rowstrav[5].description = '8';
    rowstrav[5].monetized = '12';

    setweight(107);
    let fee = 300*(15+17+18+16+14+27);
    let revenue = 400*(15+17+18) + 420*(16+14+27);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit);

    settrav([...rowstrav]);


  } , 11750), []);

   useEffect(() => void setTimeout(() => {

    rows[5].showColor = true;
    rowstrav[4].showColor = true;

    rows[6].showIcon = true;
    rows[6].status = 'F+G';
    rows[6].price = '420 ⇧';
    set([...rows]);

    rowstrav[5].matchedtwoview = true;
    rowstrav[5].matchedtwo = 'EDXJCY';
    rowstrav[5].showIcon = false;
    rowstrav[5].showIcontwo = true;
    rowstrav[5].description = '✅';
    rowstrav[5].monetized = '20';

    rowstrav[6].matched = 'EDXJCY';
    rowstrav[6].showIcon = true;
    rowstrav[6].description = '8';
    rowstrav[6].monetized = '2';

    setweight(117);
    let fee = 300*(15+17+18+16+14+27+10);
    let revenue = 400*(15+17+18) + 420*(16+14+27+10);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit);


    settrav([...rowstrav]);

   } , 13800), []);

   useEffect(() => void setTimeout(() => {

    rows[6].showColor = true;
    rowstrav[5].showColor = true;

    rows[7].showIcon = true;
    rows[7].status = 'G+H';
    rows[7].price = '420 ⇧';
    set([...rows]);

    rowstrav[6].matchedtwoview = true;
    rowstrav[6].matchedtwo = '0L8XOZ';
    rowstrav[6].showIcon = false;
    rowstrav[6].showIcontwo = true;
    rowstrav[6].description = '✅';
    rowstrav[6].monetized = '10';

    rowstrav[7].matched = '0L8XOZ';
    rowstrav[7].showIcon = true;
    rowstrav[7].description = '14';
    rowstrav[7].monetized = '11'; 

    setweight(136);
    let fee = 300*(15+17+18+16+14+27+10+19);
    let revenue = 400*(15+17+18) + 420*(16+14+27+10+19);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit); 


    settrav([...rowstrav]);
   } , 15850), []);

   useEffect(() => void setTimeout(() => {

    rows[7].showColor = true;
    rowstrav[6].showColor = true;

    rows[8].showIcon = true;
    rows[8].status = 'H+I';
    rows[8].price = '445 ⇧⇧';
    set([...rows]);

    rowstrav[7].matchedtwoview = true;
    rowstrav[7].matchedtwo = '6S4D5Y';
    rowstrav[7].showIcon = false;
    rowstrav[7].showIcontwo = true;
    rowstrav[7].description = '✅';
    rowstrav[7].monetized = '25';

    rowstrav[8].matched = '6S4D5Y';
    rowstrav[8].showIcon = true;
    rowstrav[8].description = '4';
    rowstrav[8].monetized = '6';

    setweight(156);
    let fee = 300*(15+17+18+16+14+27+10+19+20);
    let revenue = 400*(15+17+18) + 420*(16+14+27+10+19)+ 445*20;
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit); 

    settrav([...rowstrav]); 

   } , 17900), []);

   useEffect(() => void setTimeout(() => {

    rows[8].showColor = true;
    rowstrav[7].showColor = true;

    rows[9].showIcon = true;
    rows[9].status = 'I+J';
    rows[9].price = '445 ⇧⇧';
    set([...rows]);

    rowstrav[8].matchedtwoview = true;
    rowstrav[8].matchedtwo = '4JFD4P';
    rowstrav[8].showIcon = false;
    rowstrav[8].showIcontwo = true;
    rowstrav[8].description = '✅';
    rowstrav[8].monetized = '10';

    rowstrav[9].matched = '4JFD4P';
    rowstrav[9].showIcon = true;
    rowstrav[9].description = '8';
    rowstrav[9].monetized = '17';

    settrav([...rowstrav]); 

    setweight(177);
    let fee = 300*(15+17+18+16+14+27+10+19+20+21);
    let revenue = 400*(15+17+18) + 420*(16+14+27+10+19)+ 445*(20+21);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit); 

    } , 19950), []);

   useEffect(() => void setTimeout(() => {

    rows[9].showColor = true;
    rowstrav[8].showColor = true;

    rows[10].showIcon = true;
    rows[10].status = 'J+K';
    rows[10].price = '445 ⇧⇧';
    set([...rows]);

    rowstrav[9].matchedtwoview = true;
    rowstrav[9].matchedtwo = 'Q25X3E';
    rowstrav[9].showIcon = false;
    rowstrav[9].showIcontwo = true;
    rowstrav[9].description = '✅';
    rowstrav[9].monetized = '25';

    rowstrav[10].matched = 'Q25X3E';
    rowstrav[10].showIcon = true;
    rowstrav[10].description = '8';
    rowstrav[10].monetized = '7';

    settrav([...rowstrav]); 

    setweight(192);
    let fee = 300*(15+17+18+16+14+27+10+19+20+21+15);
    let revenue = 400*(15+17+18) + 420*(16+14+27+10+19)+ 445*(20+21+15);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit); 


   } , 22000), []);

   useEffect(() => void setTimeout(() => {

    rows[10].showColor = true;
    rowstrav[9].showColor = true;

    rows[11].showIcon = true;
    rows[11].status = 'K+L';
    rows[11].price = '460 ⇧⇧⇧';
    set([...rows]);

    rowstrav[10].matchedtwoview = true;
    rowstrav[10].matchedtwo = 'IGEFZM';
    rowstrav[10].showIcon = false;
    rowstrav[10].showIcontwo = true;
    rowstrav[10].description = '✅';
    rowstrav[10].monetized = '15';

    rowstrav[11].matched = 'IGEFZM';
    rowstrav[11].showIcon = true;
    rowstrav[11].description = '13';
    rowstrav[11].monetized = '12';

    settrav([...rowstrav]); 

    setweight(212);
    let fee = 300*(15+17+18+16+14+27+10+19+20+21+15+20);
    let revenue = 400*(15+17+18) + 420*(16+14+27+10+19)+ 445*(20+21+15)+ 460*(20);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit); 


   } , 24050), []);

   useEffect(() => void setTimeout(() => {

    rows[11].showColor = true;
    rowstrav[10].showColor = true;

    rows[12].showIcon = true;
    rows[12].status = 'L+M';
    rows[12].price = '460 ⇧⇧⇧';
    set([...rows]);

    rowstrav[11].matchedtwoview = true;
    rowstrav[11].matchedtwo = 'K8PES1';
    rowstrav[11].showIcon = false;
    rowstrav[11].showIcontwo = true;
    rowstrav[11].description = '✅';
    rowstrav[11].monetized = '25';

    rowstrav[12].matched = 'K8PES1';
    rowstrav[12].showIcon = true;
    rowstrav[12].description = '8';
    rowstrav[12].monetized = '7';

    settrav([...rowstrav]); 

    setweight(232);
    let fee = 300*(15+17+18+16+14+27+10+19+20+21+15+20+20);
    let revenue = 400*(15+17+18) + 420*(16+14+27+10+19)+ 445*(20+21+15)+ 460*(20+20);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit);

     } , 26100), []);


   useEffect(() => void setTimeout(() => {

    rows[12].showColor = true;
    rowstrav[11].showColor = true;

    rows[13].showIcon = true;
    rows[13].status = 'M+N';
    rows[13].price = '460 ⇧⇧⇧';
    set([...rows]);

    rowstrav[12].matchedtwoview = true;
    rowstrav[12].matchedtwo = 'BEYZDN';
    rowstrav[12].showIcon = false;
    rowstrav[12].showIcontwo = true;
    rowstrav[12].description = '✅';
    rowstrav[12].monetized = '15';

    rowstrav[13].matched = 'BEYZDN';
    rowstrav[13].showIcon = true;
    rowstrav[13].description = '✅';
    rowstrav[13].monetized = '10';

    settrav([...rowstrav]); 

    setweight(250);
    let fee = 300*(15+17+18+16+14+27+10+19+20+21+15+20+20+18);
    let revenue = 400*(15+17+18) + 420*(16+14+27+10+19)+ 445*(20+21+15)+ 460*(20+20+18);
    let profit = (revenue - fee) * 0.85;
    setFee(fee);
    setProfit(profit);



     } , 28150), []);

   useEffect(() => void setTimeout(() => {

    rows[13].showColor = true;
    rowstrav[12].showColor = true;
    rowstrav[13].showColor = true;

    set([...rows]);
    settrav([...rowstrav]);
  } , 30200), []);


  return (
    <div className="row" style={{ marginTop: '10px', marginLeft: '10px', width: '100%' }}>
    <img className="beckbaglogo" src={'https://firebasestorage.googleapis.com/v0/b/beckfriends-2-a4131.appspot.com/o/beckbag_logo_solid_modified_small.png?alt=media&token=fa4e1225-d665-484f-9db1-ac6f369da512'} alt="logo" style={{ width: '13%', marginLeft: '5px', position: 'absolute', left: '0px', top: '10px' }} />
     <Helmet>
                <meta charSet="utf-8" />
                <title>BeckBags Demo | How BeckBags works</title>
                <link rel="canonical" href="http://beckbags.tech/demo" />
            </Helmet>
      <div className="columnhalf" style={{ marginTop: '5%' }}>
        <div className="list">
          <div className="row">
          <div className="columnotherothernew" style={{ textAlign: 'center', color: 'black' }}>Package ID</div>
          <div className="columnotherothernew" style={{ textAlign: 'center', color: 'black' }}>Booking Date</div>
          <div className="columnotherothernew" style={{ borderLeftWidth: '0px', textAlign: 'center', color: 'black' }}>Dynamic Price (₹)</div>
          <div className="columnotherothernew" style={{ borderLeftWidth: '0px', textAlign: 'center', color: 'black' }}>Weight (kgs)</div>
          <div className="columnotherothernew" style={{ borderLeftWidth: '0px', textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: 'black' }}>Matched ID</div>
        </div>
          
          {rows.map((item, index) => (
            <div class="cell">
                <div class="details" style={{ backgroundImage: item.css }}>
                  { (!item.showIcon) && <div className="row">
                    <div className="columnotherothernew">{item.id}</div>
                    <div className="columnotherothernew">{item.date}</div>
                    <div className="columnotherothernew" style={{ borderLeftWidth: '0px' }}>{item.price}</div>
                    <div className="columnotherothernew" style={{ borderLeftWidth: '0px' }}>{item.weight}</div>
                    { (!item.showIcon) && <div className="columnotherothernew" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderLeftWidth: '0px', color: 'red' }}><HourglassEmptyIcon style={{ marginRight: '5px', color: '#003a3d' }} /></div>}
                    { (item.showIcon) && <div className="columnotherothernew " style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderLeftWidth: '0px', color: 'green', fontWeight: 'bold', fontSize: '20px' }}><AirlineSeatReclineExtra style={{ marginRight: '10px' }} />{item.status}</div>}
                  </div>}
                  { (item.showIcon) && <BouncyDiv className="row">
                    <div className="columnotherothernew">{item.id}</div>
                    <div className="columnotherothernew">{item.date}</div>
                    <div className="columnotherothernew" style={{ borderLeftWidth: '0px' }}>{item.price}</div>
                    <div className="columnotherothernew" style={{ borderLeftWidth: '0px' }}>{item.weight}</div>
                    { (!item.showIcon) && <div className="columnotherothernew" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderLeftWidth: '0px', color: 'red' }}><HourglassEmptyIcon style={{ marginRight: '5px', color: '#003a3d' }} /></div>}
                    { (item.showIcon) && (!item.showColor) && <div className="columnotherothernew " style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderLeftWidth: '0px', color: '#003a3d', fontFamily: 'WalsheimBold', fontSize: '20px' }}><AirlineSeatReclineExtra style={{ marginRight: '5px', }} />{item.status}</div>}
                    { (item.showIcon) && (item.showColor) && <div className="columnotherothernew " style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderLeftWidth: '0px', color: 'black', fontFamily: 'WalsheimBold', fontSize: '20px' }}><AirlineSeatReclineExtra style={{ marginRight: '5px', }} />{item.status}</div>}
                  </BouncyDiv>}
                </div>
              </div>
        ))}
        </div>
      </div>
      <div className="columnhalftrav" style={{ marginTop: '5%' }}>
        <div className="list">
          <div className="row">
          <div className="columnotherother" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', width: '31%', color: 'black' }}>Matched ID</div>
          <div className="columnotherother" style={{ textAlign: 'center', color: 'black' }}>Traveler ID</div>
          <div className="columnotherother" style={{ borderLeftWidth: '0px', textAlign: 'center', color: 'black' }}>Weight Monetized (kgs)</div>
          <div className="columnotherother" style={{ borderLeftWidth: '0px', textAlign: 'center', color: 'black' }}>Pending Weight (kgs)</div>
        </div>
         
        {rowstrav.map((person, index) => (
        <div class="cell">
                <div class="details" style={{ backgroundImage: person.css }}>
                  { (person.showIcontwo) && <BouncyDiv className="row">
                    { (!person.showIcontwo) && <div className="columnotherother" style={{ borderLeftWidth: '0px', width: '31%' }}><HourglassEmptyIcon style={{ marginRight: '5px', color: 'red' }} /></div>}
                    { (person.showIcontwo) && (!person.showColor) && <div className="columnotherother" style={{ borderLeftWidth: '0px', color: 'red', fontFamily: 'WalsheimBold', letterSpacing: '5px', width: '31%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', fontSize: '20px' }}><div>{person.matched}</div>{ (person.matchedtwoview) && <div>{person.matchedtwo}</div>}</div>}
                    { (person.showIcontwo) && (person.showColor) && <div className="columnotherother" style={{ borderLeftWidth: '0px', color: 'black', fontFamily: 'WalsheimBold', letterSpacing: '5px', width: '31%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', fontSize: '20px' }}><div>{person.matched}</div>{ (person.matchedtwoview) && <div>{person.matchedtwo}</div>}</div>}
                    <animated.div className="columnotherother" ><AirlineSeatReclineExtra style={{ marginRight: '5px' }} />{person.name}</animated.div>
                    <div className="columnotherother" style={{ borderLeftWidth: '0px' }}>{person.monetized}</div>
                    <div className="columnotherother" style={{ borderLeftWidth: '0px' }}>{person.description}</div>
                  </BouncyDiv>}
                  { (person.showIcon) && <BouncyDiv className="row">
                    { (!person.showIcon) && <div className="columnotherother" style={{ borderLeftWidth: '0px', width: '31%' }}><HourglassEmptyIcon style={{ marginRight: '5px', color: 'red' }} /></div>}
                    { (person.showIcon) && (!person.showColor) && <div className="columnotherother" style={{ borderLeftWidth: '0px', color: 'red', fontFamily: 'WalsheimBold', letterSpacing: '5px', width: '31%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', fontSize: '20px' }}><div>{person.matched}</div>{ (person.matchedtwoview) && <div>{person.matchedtwo}</div>}</div>}
                    { (person.showIcon) && (person.showColor) && <div className="columnotherother" style={{ borderLeftWidth: '0px', color: 'black', fontFamily: 'WalsheimBold', letterSpacing: '5px', width: '31%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', fontSize: '20px' }}><div>{person.matched}</div>{ (person.matchedtwoview) && <div>{person.matchedtwo}</div>}</div>}
                    <animated.div className="columnotherother" ><AirlineSeatReclineExtra style={{ marginRight: '5px' }} />{person.name}</animated.div>
                    <div className="columnotherother" style={{ borderLeftWidth: '0px' }}>{person.monetized}</div>
                    <div className="columnotherother" style={{ borderLeftWidth: '0px' }}>{person.description}</div>
                  </BouncyDiv>}
                  { (!person.showIcon) && (!person.showIcontwo) && <div className="row">
                    { (!person.showIcon) && <div className="columnotherother" style={{ borderLeftWidth: '0px', width: '31%' }}><HourglassEmptyIcon style={{ marginRight: '5px', color: 'red' }} /></div>}
                    { (person.showIcon) && <div className="columnotherother" style={{ borderLeftWidth: '0px', color: 'green', width: '31%' }}>{person.matched}</div>}
                    <animated.div className="columnotherother"><AirlineSeatReclineExtra style={{ marginRight: '5px' }} />{person.name}</animated.div>
                    <div className="columnotherother" style={{ borderLeftWidth: '0px' }}>{person.monetized}</div>
                    <div className="columnotherother" style={{ borderLeftWidth: '0px' }}>{person.description}</div>
                  </div>}
                </div>
              </div>
        ))}
        </div>
      </div>
      <div className="" style={{ float: 'right', position: 'absolute', right: '12%', top: '35%', background: 'white', border: '1px solid #e8e8e8', padding: '25px', borderRadius: '10px' }}>
        <h1 className="countertext" style={{ fontSize: '2.5em', fontWeight: 'bold' }}>Weight Monetized</h1>
        <h2 className="countertext"  style={{ fontSize: '2.5em', fontWeight: 'bold', color: '#ff5a5f' }}>{weight} kgs</h2>
        <h1 className="countertext" style={{ fontSize: '2.5em', fontWeight: 'bold' }}>Traveler Fees</h1>
        <h2 className="countertext"  style={{ fontSize: '2.5em', fontWeight: 'bold', color: '#008489' }}> ₹ {revenue}</h2>
        <h1 className="countertext" style={{ fontSize: '2.5em', fontWeight: 'bold' }}> Total Profit</h1>
        <h2 className="countertext" style={{ fontSize: '2.5em', fontWeight: 'bold', color: 'darkgreen' }}> ₹ {profit}</h2>
      </div> 
    </div>
  )
}

export default AppAltOne;

