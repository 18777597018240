import { render } from 'react-dom';
import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import shuffle from 'lodash/shuffle';
import AirlineSeatReclineExtra from '@material-ui/icons/AirlineSeatReclineExtra';
import GroupIcon from '@material-ui/icons/Group';
import data from './data';
import travelerdata from './travelerdata';
import './styles.css';
import './index.css';

function array_move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
};

function AppAltOne() {
  const [rows, set] = useState(data)
  const [rowstrav, settrav] = useState(travelerdata)
  const [weight, setweight] = useState(0)
  const [revenue, setFee] = useState(0)
  const [profit, setProfit] = useState(0)

  return (
    <div style={{ width: '100%', height: '100%' }}>
    <div className="container container-pic" style={{ padding: '0px', width: '100%', height: '100%' }}>
    <header className="o-header"> 
              <nav className="o-header-nav newheader" style={{ borderBottom: 'none', boxShadow: 'none' }}>
                <div className="o-header-nav__link color-headers"><img alt="merchant" style={{ width: '14%', position: 'absolute' ,top: '7px', left: '10px' }} src="https://firebasestorage.googleapis.com/v0/b/beckfriends-2-a4131.appspot.com/o/beckbag_logo_solid_modified_small.png?alt=media&token=fa4e1225-d665-484f-9db1-ac6f369da512" /></div>
              </nav>
            </header>
        <div className="margin-top-2em" style={{ backgroundColor: '#ffffff', margin: '0 auto', width: '100%' }}>
              <div className="top_hdr" style={{ backgroundColor: '#ffffff', margin: '0 auto', maxWidth: 1024, textAlign: 'center' }}>
                <h1 className="headertext2 header3" style={{ width: '100%', padding: '1.2em 0 0 0', fontWeight: 700, color: 'red', marginTop: '1em' }}>Send Anywhere</h1>
                <h1 className="headertext2" style={{ width: '100%', fontWeight: 400, color: '#000', marginBottom: '1em' }}>Whenever you need in a single tap</h1>
              </div>
            </div>
    </div>
    <div style={{ backgroundColor: '#ffffff', width: '100%', paddingTop: '2em', marginTop: '-5em' }}>
          <div className="row" style={{ margin: '0 auto', maxWidth: 1024, padding: '1em 2.5em' }}>
            <h1 className="headertextsubs" style={{ color: '#57585a', width: '100%', fontSize: '1.1em', fontWeight: 300 }}>Why prefer us to a courier company ?</h1>
            <h1 className="headertextdivs" style={{ marginBottom: '1.5em', width: '100%', fontWeight: 500 }}>It’s hassle free and secure</h1>
            <div className="col-md-4">
              <svg className="icon-left" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" height={88} width={88} style={{ display: 'block', height: '40%', width: '40%' }}>
                <defs>
                  <pattern id="a___-1488082004" data-name="2/2 - black" width={4} height={4} patternUnits="userSpaceOnUse" viewBox="0 0 4 4">
                    <path fill="none" d="M0 0h4v4H0z" />
                    <path d="M0 0h2v2H0z" />
                  </pattern>
                </defs>
                <path className="illustration-primary-fill" d="M82.24 88H5.75V29.15h76.49z" />
                <path d="M0 15.43h88v13.72H0z" />
                <path fill="#d6d6d5" d="M82.24 53.81v9.51H5.75v-9.51z" />
                <path d="M27.16 3.34l12.08 12.09V0h-10.7a2 2 0 0 0-1.38 3.34z" className="illustration-secondary-fill" />
                <path d="M60.84 3.34L48.76 15.43V0h10.7a2 2 0 0 1 1.38 3.34z" fill="#d6d6d5" />
                <path fill="url(#a___-1488082004)" d="M5.76 29.14h76.49v8.57H5.76z" />
                <path className="illustration-secondary-fill" d="M39.24 15.43h9.51v72.58h-9.51z" />
              </svg>
              <div className="text-des-mob">
                <h1 className="headertextdivs" style={{ width: '100%', padding: '1em 0 0.5em 0', fontWeight: 500 }}>No Packaging</h1>
                <h1 className="headertextsubs" style={{ width: '100%', paddingBottom: '3.25em', fontSize: '1.1em', fontWeight: 300 }}>Connect with the Traveller to fix exactly where and when you want the pickup</h1>
              </div>
            </div>
            <div className="col-md-4">
              <svg className="icon-left" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" height={88} width={88} style={{ display: 'block', height: '40%', width: '40%' }}>
                <defs>
                  <pattern id="a___302398095" data-name="2/2 - black" width={4} height={4} patternUnits="userSpaceOnUse" viewBox="0 0 4 4">
                    <path fill="none" d="M0 0h4v4H0z" />
                    <path d="M0 0h2v2H0z" />
                  </pattern>
                </defs>
                <path className="illustration-primary-fill" d="M16.5 5.68h60.1V88H16.5z" />
                <path fill="url(#a___302398095)" d="M76.6 65.78V88H16.5V5.68l60.1 60.1" />
                <path fill="#fff" d="M71.58 10.7v72.27H21.53V10.7z" />
                <path d="M55.7 2.08A2.08 2.08 0 0 0 53.62 0H39.48a2.08 2.08 0 0 0-2.08 2.08 2.08 2.08 0 0 1-2.08 2.08h-2.84a2.08 2.08 0 0 0-2.08 2.09v10.48h32.3V6.25a2.08 2.08 0 0 0-2.08-2.08h-2.84a2.08 2.08 0 0 1-2.08-2.09z" />
                <path d="M37.16 31.73h-9.44v-9.44h9.44v9.44zm-7.44-2h5.44v-5.44h-5.44v5.44zm7.44 15.91h-9.44V36.2h9.44v9.44zm-7.44-2h5.44V38.2h-5.44v5.44zm7.44 15.91h-9.44v-9.44h9.44v9.44zm-7.44-2h5.44v-5.44h-5.44v5.44zm33.99-25.8a4 4 0 0 1-2.91-1.25 2.06 2.06 0 0 0-3.18 0 4 4 0 0 1-5.83 0 2.05 2.05 0 0 0-3.17 0 4 4 0 0 1-5.83 0 2.11 2.11 0 0 0-1.58-.75v-2A4 4 0 0 1 44.13 29a2.05 2.05 0 0 0 3.17 0 4 4 0 0 1 5.83 0 2.06 2.06 0 0 0 3.17 0 4 4 0 0 1 5.83 0 2.11 2.11 0 0 0 1.59.75v2zm0 13.91a4 4 0 0 1-2.92-1.25 2.06 2.06 0 0 0-3.18 0 4 4 0 0 1-5.83 0 2.05 2.05 0 0 0-3.17 0 4 4 0 0 1-5.83 0 2.11 2.11 0 0 0-1.58-.75v-2a4 4 0 0 1 2.91 1.25 2.05 2.05 0 0 0 3.17 0 4 4 0 0 1 5.83 0 2.06 2.06 0 0 0 3.17 0 4 4 0 0 1 5.83 0 2.11 2.11 0 0 0 1.59.75v2zm0 13.91a4 4 0 0 1-2.92-1.25 2.06 2.06 0 0 0-3.18 0 4 4 0 0 1-5.83 0 2.05 2.05 0 0 0-3.17 0 4 4 0 0 1-5.83 0 2.11 2.11 0 0 0-1.58-.75v-2a4 4 0 0 1 2.91 1.25 2.05 2.05 0 0 0 3.17 0 4 4 0 0 1 5.83 0 2.06 2.06 0 0 0 3.17 0 4 4 0 0 1 5.83 0 2.11 2.11 0 0 0 1.59.75v2zm0 13.92a4 4 0 0 1-2.92-1.25 2.06 2.06 0 0 0-3.18 0 4 4 0 0 1-5.83 0 2.05 2.05 0 0 0-3.17 0 4 4 0 0 1-5.83 0 2.11 2.11 0 0 0-1.58-.75v-2a4 4 0 0 1 2.91 1.25 2.05 2.05 0 0 0 3.17 0 4 4 0 0 1 5.83 0 2.06 2.06 0 0 0 3.17 0 4 4 0 0 1 5.83 0 2.11 2.11 0 0 0 1.59.75v2zm-26.55.01h-9.44v-9.44h9.44v9.44zm-7.44-2h5.44v-5.44h-5.44v5.44z" fill="#d6d6d5" />
                <circle cx="46.55" cy="5.28" r="2.35" fill="#fff" />
                <path className="illustration-secondary-fill" d="M30.4 10.7h32.31v2H30.4z" />
                <path d="M11.57 6.02h4.94v82.32h-4.94z" />
              </svg>
              <div className="text-des-mob">
                <h1 className="headertextdivs" style={{ width: '100%', padding: '1em 0 0.5em 0', fontWeight: 500 }}>Identity Verification</h1>
                <h1 className="headertextsubs" style={{ width: '100%', paddingBottom: '3.25em', fontSize: '1.1em', fontWeight: 300 }}>We perform extensive background check before connecting you to a Traveller</h1>
              </div>
            </div>
            <div className="col-md-4">
              <svg className="icon-left" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" height={88} width={88} style={{ display: 'block', height: '40%', width: '40%' }}>
                <defs>
                  <pattern id="a___-1050087185" data-name="2/2 - black" width={4} height={4} patternTransform="translate(-4.4)" patternUnits="userSpaceOnUse" viewBox="0 0 4 4">
                    <path fill="none" d="M0 0h4v4H0z" />
                    <path d="M0 0h2v2H0z" />
                  </pattern>
                </defs>
                <path className="illustration-primary-fill" d="M0 34.79h31.29V88H0z" />
                <path className="illustration-secondary-fill" d="M31.29 34.79h31.29V88H31.29zM0 51.53h31.29v2H0z" />
                <path className="illustration-primary-fill" d="M31.29 51.53h31.29v2H31.29z" />
                <path className="illustration-secondary-fill" d="M0 69.27h31.29v2H0z" />
                <path className="illustration-primary-fill" d="M31.29 69.27h31.29v2H31.29z" />
                <circle cx="31.29" cy="52.57" r="6.14" />
                <path d="M37.78 75.75H24.8l6.49-20.91 6.49 20.91" />
                <path fill="url(#a___-1050087185)" d="M0 79.2h62.58V88H0z" />
                <path d="M52.91 21.62v13.17h-9.79V21.62A11.84 11.84 0 0 0 31.29 9.79V0a21.62 21.62 0 0 1 21.62 21.62z" className="illustration-secondary-fill" />
                <path d="M49 34.79h-2V21.62A15.74 15.74 0 0 0 31.29 5.9v-2A17.75 17.75 0 0 1 49 21.62v13.17z" className="illustration-primary-fill" />
                <path d="M9.67 21.62v13.17h9.79V21.62A11.84 11.84 0 0 1 31.29 9.79V0A21.62 21.62 0 0 0 9.67 21.62z" fill="#d6d6d5" />
                <path d="M15.56 34.79h-2V21.62A17.75 17.75 0 0 1 31.29 3.9v2a15.74 15.74 0 0 0-15.73 15.72v13.17z" fill="#fff" />
                <path fill="url(#a___-1050087185)" d="M9.67 29.64h9.79v5.15H9.67zm33.45 0h9.79v5.15h-9.79z" />
              </svg>
              <div className="text-des-mob">
                <h1 className="headertextdivs" style={{ width: '100%', padding: '1em 0 0.5em 0', fontWeight: 500 }}>Safety & Reliability</h1>
                <h1 className="headertextsubs" style={{ width: '100%', paddingBottom: '3.25em', fontSize: '1.1em', fontWeight: 300 }}>We provide insurance of upto INR 25,000 for every package shipped with us</h1>
              </div>
            </div>
          </div>
        </div>
        </div>
  )
}

export default AppAltOne;

