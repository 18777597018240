export default [
  {
    name: 'A',
    showColor: false,
    description: '25',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'B',
    showColor: false,
    description: '20',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'C',
    showColor: false,
    description: '15',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'D',
    showColor: false,
    description: '20',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'E',
    showColor: false,
    description: '15',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'F',
    showColor: false,
    description: '20',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'G',
    showColor: false,
    description: '10',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'H',
    showColor: false,
    description: '25',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'I',
    showColor: false,
    description: '10',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'J',
    showColor: false,
    description: '25',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'K',
    showColor: false,
    description: '15',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'L',
    showColor: false,
    description: '25',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'M',
    showColor: false,
    description: '15',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  },
  {
    name: 'N',
    showColor: false,
    description: '10',
    monetized: '---',
    css: 'linear-gradient(135deg, #fff 0%, #006b70 50%, #003a3d 100%)',
    height: 50,
    showIcon: false,
    showIcontwo: false,
    matched: '',
    matchedtwo: '',
    matchedtwoview: false
  }
]
